<!-- 系列 -->
<template>
    <div style="width:22.45rem;height:10.6625rem;overflow: hidden;">
        <!-- 左边 -->
       <div class="tabs" v-if="tabFlag === 2">
           <div :class="num===index?'active tab-item':'tab-item'" v-for="(item,index) in tabsData" :key="index" @click="handleClick(item.id, index)">
               <!-- tab-item -->
                <!-- icon -->
                <div class="iconBox">
                    <img :src="num===index?w_topIcon:b_topIcon" alt="" v-if="index === 0" style="width:100%;height:100%">
                    <img :src="num===index?w_bottomIcon:b_bottomIcon" alt="" v-if="index === 1" style="width:100%;height:100%">
                </div>
                <!-- 年龄段 -->
                <div class="gradeName">{{item.gradeName}}</div>
                <div class="age">({{item.age}})</div>
           </div>
       </div>
       <div class="tabs" v-if="tabFlag === 1">
           <div :class="num===index?'active tab-item':'tab-item'" v-for="(item,index) in tabsSmall" :key="index" @click="handleClick(item.id, index)">
               <!-- tab-item -->
                <!-- icon -->
                <div class="iconBox">
                    <img :src="num===index?small_class:small_class" alt="" v-if="index === 0" style="width:100%;">
                    <img :src="num===index?middle_class:middle_class" alt="" v-if="index === 1" style="width:100%;">
                    <img :src="num===index?big_class:big_class" alt="" v-if="index === 2" style="width:100%;">
                </div>
                <!-- 年龄段 -->
                <div class="gradeName">{{item.gradeName}}</div>
           </div>
       </div>
       <!-- 右边 -->
       <div class="content" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)">
           <!-- 标题 -->
           <div class="couseTitle">
               <div class="couseText">课程安排</div>
           </div>
           <!-- 内容 -->
           <div class="couseContentBox">
               <!-- top -->
               <div class="couse-top">
                   <div class="couse-top-box" v-for="(item,index) in couseData" :key="item.id">
                       <div class="couse-item">
                           <img :src="item.thumb" alt="" style="width:100%;cursor:pointer;position: absolute;top:50%;transform: translateY(-50%);left:0;" @click="clickItem(item, index)">
                       </div>
                   </div>
               </div>
                <!-- bottom -->
                <div class="couse-bottom">
                   <el-pagination
                        @current-change="handleCurrentChange"
                        style="position: absolute;bottom:0;right:0;"
                        small
                        :current-page="pageParam.page"
                        background
                        :page-size="4"
                        layout="prev, pager, next"
                        :total="pageParam.total">
                    </el-pagination>
               </div>
           </div>
       </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import apis from '../../api/request'
import icon1 from '../../../static/images/b-treeseedlings.png'
import icon2 from '../../../static/images/b-tree.png'
import icon3 from '../../../static/images/w-treeseedlings.png'
import icon4 from '../../../static/images/w-tree.png'
import icon5 from '../../../static/images/small.png'
import icon6 from '../../../static/images/middle.png'
import icon7 from '../../../static/images/big.png'
export default {
//import引入的组件需要注入到对象中才能使用
// props:["listData","contentLoading"],
props:["info", "total", "contentLoading","nums"],
components: {},
data() {
//这里存放数据
return {
    schoolId:"",
    courseInfo:"",
    tabFlag:null,
    school_type:"",
    age_section:"",
    pageParam: { page: 1, total:this.total },
    category_id:"1",
    loading:false,
    couseData:[],
    num:0,
    small_class:icon5,
    middle_class:icon6,
    big_class:icon7,
    b_topIcon:icon1,
    b_bottomIcon:icon2,
    w_topIcon:icon3,
    w_bottomIcon:icon4,
    tabsData:[
        {
            id:1,
            gradeName:"小年龄段",
            age:"1-3年级",
            icon:""
        },
        {
            id:2,
            gradeName:"大年龄段",
            age:"3-5年级",
        }
    ],
    tabsSmall:[
        {
            id:3,
            gradeName:"小班"
        },
        {
            id:4,
            gradeName:"中班"
        },
         {
            id:5,
            gradeName:"大班"
        }
    ]
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {
    // nums(val){
    //     console.log('128', val);

    // },
    info:{
        handler(val){
            // console.log('129', val);
            this.couseData = val
        }
    },
    total(val){
        // console.log('134', val);
        this.pageParam.total = val
    },
    // category_id(val){
    //     console.log('', val);
    //     this.num = 0
    // },
    contentLoading(val){
        // console.log('145', val);
        this.loading = val
    }
},
//方法集合
methods: {
    clickItem(item,index){//点击某个课程进入详情页
        // console.log(this.age_section);
        localStorage.setItem("courseId", item.id)
        localStorage.setItem("courseName", item.title)
        // console.log('========')
        // console.log(localStorage.getItem("schoolType"))
        // console.log('========')
            // console.log('11111')
            // this.$router.push(`/details/list/${this.couseData[index].id}`)
        this.$router.push(`/home/series/detail/${this.couseData[index].id}`)
        // console.log(`/home/series/detail/${this.couseData[index].id}`);
    },
    handleClick(item,index){//点击年龄段
        // localStorage.setItem("classId", item)
        this.age_section = item
        localStorage.setItem("ageSection", item)
        this.pageParam.page = 1 
        this.loading = true
        this.num = index //动态切换按钮
        // if(index === 0){
        //     this.bottomIcon = icon2
        //     this.topIcon = icon3
        // }
        //  if(index === 1){
        //     this.topIcon = icon1
        //     this.bottomIcon = icon4
        // }
        this.category_id = localStorage.getItem("categoryId")
        // console.log('178', this.category_id);
        let args = {
            category_id:this.category_id,
            age_section: item,
            page:this.pageParam.page,
            limit:4,
            school_id:this.schoolId
        }
        this.$get(apis.getCourse, args).then(res=>{
            if(res.code == 0){
                this.couseData = res.data.list
                this.pageParam.total = res.data.total
                this.loading = false
            }
            // console.log('才不是他', 22222);
            this.loading = false //请求完成关闭loading
            // console.log(this.couseData);
        }).catch(err=>{
            console.log(err);
            this.loading = false //请求完成关闭loading
        })
    },
    // init(arg,info,total,flag){//初始化
    //     this.category_id = arg
    //     this.couseData = info
    //     this.pageParam.total = total
    //     this.loading = flag
    // },
    handleCurrentChange(val){//页数改变
        this.loading = true
        this.pageParam.page = val
        let args = {
            category_id:this.category_id*1,
            age_section: this.age_section*1,
            page:this.pageParam.page,
            limit: 4,
            school_id:this.schoolId
        }
        // console.log('202',args);
        this.$get(apis.getCourse, args).then(res=>{
            if(res.code == 0){
                this.couseData = res.data.list
                this.pageParam.total = res.data.total
                this.loading = false
            }
            this.loading = false
        }).catch(err=>{
            console.log(err);
            this.loading = false
        })
    }
},
beforeCreate() {}, //生命周期 - 创建之前
//生命周期 - 创建完成（可以访问当前this实例）
created() {
},
beforeMount() {}, //生命周期 - 挂载之前
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
    // if(this.num === 0){
    //     this.couseData = this.couseaData
    // }
    this.schoolId = localStorage.getItem("schoolId")
    this.age_section = localStorage.getItem("ageSection")
    // this.category_id = localStorage.getItem("categoryId")
    // console.log('225', this.category_id);
    this.school_type = localStorage.getItem("schoolType")
    if(this.age_section == 1 || this.age_section == 3){
        this.num = 0
    }
    if(this.age_section == 2 || this.age_section == 4){
        this.num = 1
    }
    if(this.age_section == 5){
        this.num = 3
    }
    // console.log(this.school_type);
    if(this.school_type === "1"){
        this.tabFlag = 1
        // this.handleClick(this.tabsSmall[0].id,0)
        // console.log("257",this.tabFlag);
    }
    if(this.school_type === "2"){
        this.tabFlag = 2
        // this.handleClick(this.tabsData[0].id,0)
        // console.log("257",this.tabFlag);
    }
},
beforeUpdate() {
}, //生命周期 - 更新之前
updated() {
    this.category_id = localStorage.getItem("categoryId")
}, //生命周期 - 更新之后
beforeDestroy() {
}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
    .couse-top-box{ 
        width:50%;
        height:50%;
        position: relative;
        float: left;
    }
    .tabs{
        width: 2.425rem;
        /* height:3.5375rem; */
        margin-top:0.8875rem;
        margin-left:2.3375rem;
        background-color: #fff;
        border:1px solid transparent;
        border-radius: 0.1rem;
        float: left;
        position: absolute;
        box-shadow: 0px 6px 36px rgba(135, 135, 135, 0.2);
        z-index: 3;
    }
    .tab-item{
        width:100%;
        height:1.7687rem;
        /* margin:0 auto; */
        /* display: flex; */
        border-radius: 0.1rem;
        overflow: hidden;
        /* flex-direction:column; */
        /* justify-content: center; */
        color:#43412DFF;
        /* align-items: center; */
        cursor: pointer;
    }
    .gradeName{
        width:100%;
        font-size: 0.225rem;
        text-align: center;
        /* color:#43412DFF; */
    }
    .age{
        font-size: 0.225rem;
        transform: scale(0.8);
        /* -webkit-transform: scale(0.8);     for Chrome || Safari */
        /* -moz-transform: scale(0.8);        for Firefox */
        /* -ms-transform: scale(0.8);         for IE */
        /* -o-transform: scale(0.8);          for Opera */
        /* color:#43412DFF; */
    }
    .iconBox{
        width:0.55rem;
        height:0.55rem;
        /* margin-left:0.9125rem; */
        /* margin-top:0.3625rem; */
        margin:0.3625rem auto 0.1rem;
        /* background-color: yellow; */
    }
    .active{
        background-color:#5B87FF;
        color:#fff;
        box-shadow: 0px 6px 36px rgba(12, 48, 129, 0.23);
    }
    .content{
        width: 14.04rem;
        height:9rem;
        float: left;
        position: absolute;
        z-index: 3;
        margin-top:0.8875rem;
        margin-left:5.25rem;
        background-color: #fff;
        box-shadow: 0px 6px 48px rgba(148, 148, 148, 0.13);
    }
    .couseTitle{
        width: 2.6125rem;
        height: 0.5rem;
        margin-left: -0.0875rem;
        margin-top: 0.225rem;
        position: relative;
        background-color: #5B87FF;
    }
    .couseText{
        width: 1.4rem;
        height: 0.4125rem;
        position: absolute;
        top:50%;
        left:50%;
        font-size: 0.3rem;
        margin-left: -0.7rem;
        margin-top: -0.20625rem;
        color: #fff;
    }
    .couseContentBox{
        width:12.875rem;
        height:7.7125rem;
        /* background-color: yellow;    */
        margin:0.35rem  auto;
        position: relative;
    }
    .couse-item{
        width: 6.25rem;
        height: 3rem;
        /* background-color: yellow; */
        overflow: hidden;
        /* margin: 0 auto; */
        position: absolute;
        left:50%;
        top:0;
        margin-left: -3.125rem;
        /* float: left; */
        /* margin-bottom:0.657rem; */
        /* margin-right: 0.3rem; */
    }
    .couse-bottom{
        width: 100%;
        height: 0.725rem;
        position: absolute;
        bottom:0;
        /* background-color: blue; */
    }
    .couse-top{
        width: 100%;
        height:7.1125rem;
        position: absolute;
        top:0;
        /* background-color: red; */
    }
</style>